import { Center, CenterProps, Heading, VStack } from "@chakra-ui/react";
import BaseGreenButton from "../baseGreenButton";

interface IBaseSectionLayout extends CenterProps {
  mainTitle: JSX.Element;
  buttonLabel: string;
  buttonAction: () => void;
}

export default function BaseSectionLayout({
  mainTitle,
  buttonAction,
  buttonLabel,
  ...props
}: IBaseSectionLayout) {
  const { children } = props;
  return (
    <Center>
      <VStack spacing={8}>
        <Heading
          fontFamily="Dream Avenue"
          fontSize={{ base: "30px", lg: "60px" }}
          color="#BB9B89"
        >
          {mainTitle}
        </Heading>
        {children}
        <BaseGreenButton buttonLabel={buttonLabel} action={buttonAction} />
      </VStack>
    </Center>
  );
}
