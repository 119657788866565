import { Center, Grid, Heading } from "@chakra-ui/react";
import PersonalCard from "../PersonalCard";

export interface IDoc {
  name: string;
  imageSrc: string;
  description: string;
  skillsList: string[];
}

const docs: IDoc[] = [
  {
    name: "DRA BARBARA TANAKA",
    imageSrc: "/assets/2.png",
    description: "CRM MA 6536 • RQEs 5094 | 5096 | 5095",
    skillsList: [
      "• Possui graduação em Medicina pela Universidade Federal do Maranhão",
      "• Residência Médica em Pediatria Geral no Hospital Geral Waldemar Alcantara",
      "• Residência Médica em Cardiologia Pediátrica no Instituto do Coração - HCFMUSP",
      "• Especialização em Ecocardiografia Pediátrica e Fetal pelo Instituto do Coração da Faculdade de Medicina da USP-SP",
      "• Título em Ecocardiografia pela Associação Médica Brasileira.",
    ],
  },
  {
    name: "DRA NATALIA MOREIRA",
    imageSrc: "/assets/natalia-pedcor.png",
    description: " CRM 7281 • RQEs 1684 | 4790",
    skillsList: [
      "• Graduação em Medicina pela Universidade Federal do Maranhão",
      "• Residência médica em Pediatria pela Santa Casa - SP",
      "• Residência médica em cardiologia pediátrica e cardiopatia congênita do adulto pelo Instituto do Coração da Faculdade de medicina da USP - SP",
      "• Especialização em ecocardiografia pediátrica e fetal pelo Instituto do Coração da Faculdade de medicina da USP - SP",
      "• Especialização em ecocardiografia e cardiologia fetal pelo instituto Lilian Lopes.",
    ],
  },
  {
    name: "DRA THAYSSA POLARY",
    imageSrc: "/assets/4.png",
    description: " CRM MA 6984 • RQEs  3773 | 3774 | 5670",
    skillsList: [
      "• Possui graduação em Medicina pela Universidade Federal do Maranhão",
      "• Residência Médica em Pediatria no Hospital Darcy Vargas (SP)",
      "• Residência Médica em Cardiologia Pediátrica pelo Instituto do Coração HC-FMUSP (SP)",
      "• Participou do Programa de Complementação Especializada em Ecocardiografia Pediátrica e Fetal no Instituto do Coração HC-FMUSP (SP)",
      "• Título em Ecocardiografia pela Associação Médica Brasileira",
    ],
  },
];

export default function SectionC() {
  return (
    <Center
      id="s3"
      mb={{ base: "20px", lg: "40px" }}
      w="full"
      h={{ base: "2900px", lg: "1300px" }}
      bg={{
        base: "repeating-linear-gradient(#A9B9B0, #A9B9B0 400px, #BB9B89 400px, #BB9B89 900px, #D8D8D8 800px, #D8D8D8 2200px, #6D484B 2200px, #6D484B 2700px, #D8D8D8 2700px, #D8D8D8 3000px)",
        xl: "repeating-linear-gradient(#A9B9B0, #A9B9B0 50%, #D8D8D8 50%, #D8D8D8)",
      }}
      flexDir={"column"}
    >
      <Heading
        fontFamily={"Dream Avenue"}
        fontSize={{ base: "40px", xl: "120px" }}
        fontWeight={200}
        py="12px"
        color="gray.50"
      >
        QUEM SOMOS
      </Heading>
      <Grid
        px="160px"
        pt={{ base: 0, lg: "40px" }}
        maxW="1800px"
        columnGap={12}
        templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
      >
        {docs.map((doc, index) => (
          <PersonalCard key={index} doc={doc} index={index} />
        ))}
      </Grid>
    </Center>
  );
}
