import "./App.css";
import {
  Button,
  Center,
  Grid,
  Icon,
  Link,
  useColorMode,
  useMediaQuery,
} from "@chakra-ui/react";
import Header from "./components/header";
import SectionA from "./components/sectiona";
import SectionB from "./components/sectionb";
import SectionC from "./components/sectionc";
import BaseSectionLayout from "./components/baseSectionLayout";
import FAQAccordion from "./components/Accordion";
import { useEffect, useState } from "react";
//@ts-ignore-next-line
import Instafeed from "instafeed.js";
import { PhoneIcon } from "@chakra-ui/icons";
export function handleScrollToSection(
  section: "s1" | "s2" | "s3" | "s4" | "s5" | "s6"
) {
  const sectionFoundInDOM = document.getElementById(section);
  if (sectionFoundInDOM) {
    sectionFoundInDOM.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

function App() {
  const [isMobile] = useMediaQuery("(max-width: 1280px");
  const [windowW, setWindowW] = useState<number>(window.innerWidth);
  const { toggleColorMode, colorMode } = useColorMode();
  useEffect(() => {
    function handleResize() {
      setWindowW(window.innerWidth);
    }
    if (colorMode === "dark") {
      toggleColorMode();
    }
    function loadInstafeed() {
      var feed = new Instafeed({
        get: "user",
        accessToken:
          "IGQWROdE9KeE5OUl8xNmVUOFZAIWDV3WlFCQURKS256cDk3ZAUYyTkV2S3czV3FqWi1NX2hSby1TMkdKNS14eGRvWDVHbzhQWThYWDlEYVhSb1RaMUhjYmoxaU5VemIzbzNfOVRkcnVNTXVRVGNSdTVENkZAJb1J1QmcZD",
        limit: 9,
      });
      feed.run();
      console.log(feed);
    }
    loadInstafeed();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleColorMode, colorMode]);

  return (
    <>
      <Button
        as={Link}
        borderWidth={"6px"}
        borderColor={"white"}
        size="lg"
        colorScheme="whatsapp"
        h={{ base: "60px", lg: "80px" }}
        w={{ base: "60px", lg: "80px" }}
        position={"fixed"}
        _hover={{
          transform: "scale(1.05)",
        }}
        bottom={{ base: "12px", lg: "28px" }}
        zIndex={9999}
        right={{ base: "12px", lg: "28px" }}
        href="https://api.whatsapp.com/send?phone=5598991289800&text=Olá%2C%20vim%20através%20do%20site.%20Gostaria%20de%20agendar%20uma%20consulta"
        isExternal
        borderRadius={"full"}
      >
        <Icon
          as={PhoneIcon}
          h={{ base: "30px", lg: "40px" }}
          w={{ base: "30px", lg: "60px" }}
        />
      </Button>
      <div className="App">
        <Header />
        <SectionA />
        <SectionB />
        <SectionC />
        <Center id="s4" w="full">
          <BaseSectionLayout
            mainTitle={
              <>
                DÚVIDAS
                {isMobile && <br />} FREQUENTES
              </>
            }
            buttonLabel={"Quero agendar agora"}
            buttonAction={() => {
              const anchor = document.createElement("a");
              anchor.href =
                "https://api.whatsapp.com/send?phone=5598991289800&text=Olá%2C%20vim%20através%20do%20site.%20Gostaria%20de%20agendar%20uma%20consulta";
              anchor.target = "_blank";
              anchor.click();
            }}
          >
            <FAQAccordion />
          </BaseSectionLayout>
        </Center>
        <Center id="s5" w="full" my={{ base: "40px", lg: "100px" }}>
          <BaseSectionLayout
            mainTitle={<>NOSSO INSTAGRAM</>}
            buttonLabel={"Acompanhar no Instagram"}
            buttonAction={() => {
              const anchor = document.createElement("a");
              anchor.href = "https://www.instagram.com/pedcorsaoluis/";
              anchor.target = "_blank";
              anchor.click();
            }}
          >
            <Grid
              id="instafeed"
              maxW="1200px"
              px="20px"
              templateColumns={"repeat(3, 1fr)"}
              rowGap={"4px"}
              columnGap={"4px"}
            ></Grid>
          </BaseSectionLayout>
        </Center>
        <Center id="s6" w="full" mb="40px">
          <BaseSectionLayout
            mainTitle={<>ONDE ESTAMOS</>}
            buttonLabel={"Agende a consulta da sua criança"}
            buttonAction={() => {
              const anchor = document.createElement("a");
              anchor.href =
                "https://api.whatsapp.com/send?phone=5598991289800&text=Olá%2C%20vim%20através%20do%20site.%20Gostaria%20de%20agendar%20uma%20consulta";
              anchor.target = "_blank";
              anchor.click();
            }}
          >
            <Center
              w="full"
              pt="20px"
              pb="32px"
              color="#6D484B"
              fontSize={{ base: "20px", lg: "24px" }}
              fontFamily={"'Glacial Indifference', Verdana;"}
              textAlign="center"
            >
              Ilha Medical, Torre 2, Sala 407 <br />
              Shopping da Ilha.
            </Center>
          </BaseSectionLayout>
        </Center>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15943.748943420633!2d-44.2548915!3d-2.5273933!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f68fd3b0ef6e31%3A0xd44e8abb92ee58b2!2sCl%C3%ADnica%20PedCor%20-%20S%C3%A3o%20Luis!5e0!3m2!1spt-BR!2sbr!4v1697635092860!5m2!1spt-BR!2sbr"
          width={windowW}
          height="450px"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Center w="full" bg="#A8B9AF" py="20px">
          <Button
            as={Link}
            href="https://www.instagram.com/_agenciaopen/"
            target="_blank"
            variant={"link"}
            color="#000"
            fontFamily={"Open Sans"}
            fontWeight="300"
          >
            Site desenvolvido por Agência Open
          </Button>
        </Center>
      </div>
    </>
  );
}

export default App;
