import {
  Center,
  Heading,
  List,
  ListItem,
  useMediaQuery,
} from "@chakra-ui/react";
import BaseGreenButton from "../baseGreenButton";

export default function SectionB() {
  const [isMobile] = useMediaQuery("(max-width: 1280px");
  return (
    <Center
      id="s2"
      position={"relative"}
      top={"1px"}
      w="full"
      p={{ base: "16px", lg: "40px" }}
      flexDir={"column"}
      bg="#A9B9B0"
      color="gray.50"
    >
      <Heading
        fontSize={{ base: "2xl", lg: "6xl" }}
        fontFamily=" 'Playfair Display', Verdana"
        textAlign={{ base: "center", lg: "left" }}
        fontWeight={300}
        color="gray.50"
      >
        Excelência em cuidado com o<br /> coração do seu pequenino!
      </Heading>
      <Center w="full" my={{ base: "24px", lg: "64px" }}>
        <List spacing={2}>
          {isMobile ? (
            <>
              <ListItem sx={styles.listItemText}>
                ♡ Cardiologia Pediátrica
              </ListItem>
              <ListItem sx={styles.listItemText}>
                ♡ Ecocardiograma Pediátrico
              </ListItem>
              <ListItem sx={styles.listItemText}>
                ♡ Ecocardiograma Fetal
              </ListItem>
              <ListItem sx={styles.listItemText}>♡ Eletrocardiograma</ListItem>
              <ListItem sx={styles.listItemText}>♡ Risco Cirúrgico</ListItem>
            </>
          ) : (
            <>
              <ListItem sx={styles.listItemText}>
                ♡ Cardiologia Pediátrica
              </ListItem>
              <ListItem sx={styles.listItemText}>
                ♡ Ecocardiograma Pediátrico e Fetal
              </ListItem>
              <ListItem sx={styles.listItemText}>
                ♡ Eletrocardiograma e Risco Cirúrgico
              </ListItem>
            </>
          )}
        </List>
      </Center>
      <Center py="16px">
        <BaseGreenButton
          buttonLabel="Agende a consulta da sua criança"
          action={() => {
            const anchor = document.createElement("a");
            anchor.href =
              "https://api.whatsapp.com/send?phone=5598991289800&text=Olá%2C%20vim%20através%20do%20site.%20Gostaria%20de%20agendar%20uma%20consulta";
            anchor.target = "_blank";
            anchor.click();
          }}
        />
      </Center>
    </Center>
  );
}

const styles = {
  listItemText: {
    fontSize: {
      base: "xl",
      lg: "5xl",
    },
  },
};
