import {
  Center,
  GridItem,
  Heading,
  Image,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IDoc } from "../sectionc";

interface IPersonalCard {
  doc: IDoc;
  index: number;
}

export default function PersonalCard({ doc, index }: IPersonalCard) {
  return (
    <GridItem id={doc.name} maxW={{ base: "300px", lg: "400px" }} m="20px">
      <VStack>
        <Center
          h={{ base: "450px", lg: "500px" }}
          w={{ base: "400px", lg: "480px" }}
          mb="20px"
          objectFit={"contain"}
        >
          <Image
            src={doc.imageSrc}
            w={{ base: "320px", lg: "760px" }}
            h={{
              base: doc.name.includes("NATALIA") ? "430px" : "500px",
              lg: doc.name.includes("NATALIA") ? "410px" : "500px",
            }}
            // maxH={"500px"}
            borderRadius="300px 300px  0 0"
            objectFit={{ base: "cover", lg: "contain" }}
          />
        </Center>
        <Heading
          color={{
            base: index % 2 === 0 ? "gray.50" : "#6D484B",
            lg: "#6D484B",
          }}
          fontSize={{ base: "2xl", lg: "3xl" }}
          fontFamily={"Dream Avenue"}
          noOfLines={1}
          whiteSpace={"nowrap"}
          fontWeight={200}
        >
          {doc.name}
        </Heading>
        <Text
          mt={"-12px"}
          mb="20px"
          color={{
            base: index % 2 === 0 ? "gray.50" : "#BB9B89",
            lg: "#BB9B89",
          }}
          noOfLines={1}
          whiteSpace={"nowrap"}
          fontSize={{ base: "lg", "2xl": "xl" }}
        >
          {doc.description}
        </Text>
        <List
          p={{ base: "12px", lg: "22px" }}
          borderRadius="12px"
          color="#6D484B"
          h={{ base: "320px", lg: "460px" }}
          bg="#A8B9AF"
          textAlign={"justify"}
          fontSize={{ base: "sm", lg: "lg" }}
        >
          {doc.skillsList.map((skill) => (
            <ListItem mb="2px" key={skill}>
              {skill}
            </ListItem>
          ))}
        </List>
      </VStack>
    </GridItem>
  );
}
