import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Text,
} from "@chakra-ui/react";
import "./style.css";
export default function FAQAccordion() {
  return (
    <Center className="accordion" w="full" p="20px">
      <Accordion allowMultiple w="full" maxW="800px" color="#BB9B89">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                O que a cardiologia pediátrica trata?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize={"lg"}>
              A cardiologia pediátrica dedica-se ao cuidado de indivíduos que
              apresentam problemas cardíacos desde o nascimento ou que os
              desenvolvem durante a infância. Isso engloba uma variedade de
              condições, como doenças reumáticas, arritmias, elevação do
              colesterol, hipertensão, infecções cardíacas, miocardiopatias
              (dilatação do coração) e outras.
            </Text>
            <Text fontSize={"lg"}>
              Contudo, o campo de atuação também inclui o acompanhamento de
              crianças que praticam atividades físicas e daquelas com doenças
              sistêmicas (obesidade, diabetes, hipertensão arterial e
              sedentarismo). Essas últimas necessitam de medidas preventivas
              para a síndrome plurimetabólica.
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                Quais sintomas do bebê devem ser investigados pelo
                cardiopediatra?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize={"lg"}>
              Sopro cardíaco, dificuldade na amamentação, ganho de peso
              insuficiente, respiração acelerada, sudorese excessiva, infecções
              respiratórias recorrentes, episódios de desmaio, desconforto no
              peito, coloração arroxeada nos lábios e dedos, batimento cardíaco
              acelerado, irritabilidade, sono agitado e pressão alta.
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                Quando procurar um cardiopediatra?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize="lg">
            <Text fontSize={"lg"}>
              O cardiopediatra exerce diversas funções, incluindo:
            </Text>
            <ul style={{ paddingLeft: "22px" }}>
              <li>
                Avaliação de crianças e adolescentes que apresentam sintomas
                cardíacos, como sopro cardíaco, fadiga durante atividades
                físicas, dificuldade no ganho de peso, infecções frequentes, dor
                torácica, palpitações, desmaios e dilatação cardíaca. O objetivo
                é diagnosticar a condição cardíaca e propor tratamentos
                adequados.
              </li>
              <li>
                Acompanhamento de indivíduos jovens com cardiopatias congênitas,
                independentemente de terem passado por cirurgias corretivas ou
                não.
              </li>
              <li>
                Avaliação de recém-nascidos com sopro cardíaco, visando
                identificar possíveis problemas cardíacos desde os primeiros
                momentos de vida.
              </li>
              <li>
                Avaliação de gestantes cujos fetos apresentam indícios de
                cardiopatia, garantindo o monitoramento adequado.
              </li>
              <li>
                Avaliação cardíaca em indivíduos submetidos a cirurgias não
                cardíacas, no período pós-operatório.
              </li>
              <li>
                Emissão de parecer sobre a aptidão de crianças e adolescentes
                para a prática de atividades físicas.
              </li>
              <li>
                Acompanhamento e avaliação de crianças e adolescentes com
                hipertensão arterial e níveis elevados de colesterol.
              </li>
            </ul>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                O que esperar de uma consulta com o cardiopediatra?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize={"lg"}>
              Uma consulta com um especialista em cardiologia pediátrica oferece
              a oportunidade de, por meio de um exame físico específico e
              suportado por exames complementares, estabelecer ou descartar um
              diagnóstico de doença cardíaca.
            </Text>
            <Text fontSize={"lg"}>
              Uma vez identificada uma condição cardíaca, o cardiopediatra
              esclarece dúvidas, fornece suporte aos pais e familiares e orienta
              quanto ao melhor curso de tratamento.
            </Text>
            <Text fontSize={"lg"}>
              Algumas doenças podem requerer apenas acompanhamento clínico,
              enquanto outras demandam intervenção medicamentosa. Há casos que
              necessitam de abordagens mais invasivas, variando de procedimentos
              de cateterismo a cirurgias, desde as mais simples até as mais
              complexas.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                Quando fazer o ecocardiograma pediátrico
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize="lg">
            <Text fontSize={"lg"}>
              O ecocardiograma pediátrico é um exame de imagem que utiliza
              ultrassom para visualizar as estruturas e o funcionamento do
              coração em crianças. Ele é frequentemente realizado para avaliar a
              saúde cardíaca e identificar possíveis anomalias. A decisão de
              quando fazer um ecocardiograma pediátrico depende de vários
              fatores, incluindo sintomas, histórico médico e recomendações do
              profissional de saúde. Aqui estão algumas situações em que um
              ecocardiograma pediátrico pode ser indicado:
            </Text>
            <ul style={{ paddingLeft: "22px" }}>
              <li>Sopros cardíacos</li>
              <li>Histórico familiar de cardiopatias</li>
              <li>Suspeita de cardiopatias congênitas</li>
              <li>
                Sintomas cardíacos: crianças que apresentam sintomas como
                cansaço excessivo, dificuldade para respirar, palpitações, dor
                no peito ou desmaios podem precisar de um ecocardiograma para
                verificar se há alguma anormalidade cardíaca subjacente.
              </li>
              <li>
                Monitoramento: crianças com cardiopatias conhecidas ou que
                passaram por cirurgias cardíacas podem precisar de
                ecocardiogramas regulares para monitorar a função cardíaca, o
                crescimento e o desenvolvimento das estruturas cardíacas.
              </li>
              <li>
                Acompanhamento de tratamento: se uma criança estiver passando
                por tratamento médico ou cirúrgico para uma condição cardíaca, o
                ecocardiograma pode ser usado para avaliar a eficácia do
                tratamento e as mudanças nas estruturas cardíacas ao longo do
                tempo.
              </li>
              <li>
                Antes de procedimentos cirúrgicos não cardíacos, especialmente
                aqueles que envolvem anestesia geral, um ecocardiograma pode ser
                solicitado para avaliar a saúde cardíaca da criança.
              </li>
            </ul>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                Quais os sinais de alerta da cardiopatia congênita?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize="lg">
            <Text fontSize={"lg"}>
              Os sinais de alerta de uma cardiopatia congênita podem variar
              dependendo da gravidade e do tipo específico da condição. Alguns
              sinais de alerta que podem indicar a presença de uma cardiopatia
              congênita incluem:
            </Text>
            <ul style={{ paddingLeft: "22px", margin: "12px 0px" }}>
              <li>
                <Text as="strong">Falta de crescimento adequado</Text>
              </li>
              <li>
                <Text as="strong">Falta de energia e cansaço excessivo</Text>
              </li>
              <li>
                <Text as="strong">Infecções respiratórias recorrentes</Text>
              </li>
            </ul>
            <ul style={{ paddingLeft: "22px", margin: "12px 0px" }}>
              <li>
                <Text fontSize={"lg"}>
                  <strong>Cianose:</strong> A cianose é uma coloração azulada da
                  pele, dos lábios ou das unhas, que pode indicar uma falta de
                  oxigênio no sangue. Isso pode ocorrer especialmente quando o
                  coração não está bombeando adequadamente o sangue oxigenado
                  para o corpo.
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  <strong>Dificuldade respiratória:</strong> bebês com
                  cardiopatias congênitas podem apresentar dificuldades para
                  respirar, respiração rápida ou problemas respiratórios, como
                  chiado.
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  <strong>Frequência cardíaca anormal:</strong> batimentos
                  cardíacos anormalmente rápidos (taquicardia) ou lentos
                  (bradicardia).
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  <strong>Sopro cardíaco:</strong> um som anormal ao ouvir o
                  coração com o estetoscópio pode ser um sinal de que algo não
                  está funcionando corretamente.
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  <strong>
                    Dificuldade de alimentação e ganho de peso insuficiente:
                  </strong>{" "}
                  bebês com cardiopatias podem cansar-se facilmente durante a
                  amamentação ou alimentação, resultando em ganho de peso
                  inadequado.
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  <strong>Desmaios ou perda de consciência:</strong> Isso pode
                  ocorrer quando o coração não é capaz de bombear sangue
                  suficiente para atender às necessidades do corpo.
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  <strong>Inchaço:</strong> edema em áreas como pés, tornozelos
                  ou abdômen pode ser um sinal de que o coração está tendo
                  dificuldade em bombear sangue eficientemente.
                </Text>
              </li>
              <li>
                <Text fontSize={"lg"}>
                  Lembrando que os sinais e sintomas podem variar amplamente com
                  base no tipo e na gravidade da cardiopatia congênita. Nem
                  todas as crianças com uma cardiopatia congênita apresentarão
                  todos esses sinais, e alguns sinais podem ser sutis ou não
                  óbvios. Caso haja qualquer preocupação em relação à saúde
                  cardíaca de uma criança, é importante procurar atendimento
                  médico para uma avaliação adequada. O diagnóstico e tratamento
                  precoces das cardiopatias congênitas são fundamentais para o
                  bem-estar da criança.
                </Text>
              </li>
            </ul>
          </AccordionPanel>
        </AccordionItem>
        {/* <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                fontSize={{ base: "18px", md: "28px" }}
                as="span"
                flex="1"
                textAlign="left"
              ></Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem> */}
      </Accordion>
    </Center>
  );
}
