import { Button } from "@chakra-ui/react";

interface IBaseGreenButton {
  buttonLabel: string;
  action: () => void;
}

export default function BaseGreenButton({
  buttonLabel,
  action,
}: IBaseGreenButton) {
  return (
    <Button
      size="lg"
      fontFamily={"'Open Sans', Verdana"}
      fontSize={{ base: "MD", lg: "3xl" }}
      h={{ base: "60px", lg: "70px" }}
      bg="#7DA85C"
      _hover={{
        bg: "#7DA85C",
        opacity: 0.95,
      }}
      borderRadius={{ base: "full", lg: "8px" }}
      onClick={action}
      color="gray.50"
      fontWeight="300"
    >
      {buttonLabel}
    </Button>
  );
}
