import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  VStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { handleScrollToSection } from "../../App";

export default function Header() {
  const [isMobile] = useMediaQuery("(max-width: 1280px");

  const drawerDisclosure = useDisclosure();

  return (
    <Center
      position={"fixed"}
      zIndex={10}
      top={0}
      right={0}
      bg="#d8d8d8"
      w="full"
    >
      {isMobile ? (
        <Flex
          pt="60px"
          pb="20px"
          px="12px"
          w="full"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Center>
            <Image
              src="/assets/logo_pedcor.png"
              objectFit="contain"
              scale={1.2}
              width="230px"
              height="100px"
            />
          </Center>
          <IconButton
            _hover={{
              cursor: "pointer",
            }}
            aria-label="menu-button"
            as={HamburgerIcon}
            color="gray.800"
            bg="transparent"
            onClick={drawerDisclosure.onOpen}
          />
          <Drawer
            isOpen={drawerDisclosure.isOpen}
            onClose={drawerDisclosure.onClose}
          >
            <DrawerOverlay />
            <DrawerContent bg="#d8d8d8">
              <DrawerHeader mb="20px" justifyContent={"flex-end"}>
                <DrawerCloseButton />
              </DrawerHeader>
              <DrawerBody
                h="full"
                display={"flex"}
                fontFamily={"'Glacial Indifference', Verdana;"}
              >
                <VStack width={"full"} spacing={5}>
                  <Button
                    variant={"link"}
                    sx={styles.drawerContentButtons}
                    onClick={() => {
                      handleScrollToSection("s2");
                      drawerDisclosure.onClose();
                    }}
                  >
                    Especialidades
                  </Button>
                  <Button
                    variant={"link"}
                    sx={styles.drawerContentButtons}
                    onClick={() => {
                      handleScrollToSection("s3");
                      drawerDisclosure.onClose();
                    }}
                  >
                    Quem Somos
                  </Button>
                  <Button
                    variant={"link"}
                    sx={styles.drawerContentButtons}
                    onClick={() => {
                      handleScrollToSection("s4");
                      drawerDisclosure.onClose();
                    }}
                  >
                    Dúvidas Frequentes
                  </Button>
                  <Button
                    variant={"link"}
                    sx={styles.drawerContentButtons}
                    onClick={() => {
                      handleScrollToSection("s6");
                      drawerDisclosure.onClose();
                    }}
                  >
                    Onde Estamos
                  </Button>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      ) : (
        <HStack
          pt="40px"
          pb="20px"
          spacing={24}
          fontFamily={"'Glacial Indifference', Verdana;"}
          color="#BB9B89"
          fontSize="40px"
        >
          <Button
            variant={"link"}
            sx={styles.horizontalStackButtons}
            onClick={() => {
              handleScrollToSection("s2");
              drawerDisclosure.onClose();
            }}
          >
            Especialidades
          </Button>
          <Button
            variant={"link"}
            sx={styles.horizontalStackButtons}
            onClick={() => {
              handleScrollToSection("s3");
              drawerDisclosure.onClose();
            }}
          >
            Quem Somos
          </Button>
          <Button
            variant={"link"}
            sx={styles.horizontalStackButtons}
            onClick={() => {
              handleScrollToSection("s4");
              drawerDisclosure.onClose();
            }}
          >
            Dúvidas Frequentes
          </Button>
          <Button
            variant={"link"}
            sx={styles.horizontalStackButtons}
            onClick={() => {
              handleScrollToSection("s6");
              drawerDisclosure.onClose();
            }}
          >
            Onde Estamos
          </Button>
        </HStack>
      )}
    </Center>
  );
}

const styles = {
  drawerContentButtons: {
    width: "100%",
    justifyContent: "flex-end",
    color: "#BB9B89",
    fontWeight: 300,
    fontSize: "24px",
  },
  horizontalStackButtons: {
    fontSize: { base: "2xl" },
    fontWeight: 300,
    color: "#BB9B89",
  },
};
