import { Center, Image, useMediaQuery } from "@chakra-ui/react";

export default function SectionA() {
  const [isMobile] = useMediaQuery("(max-width: 1280px");

  return (
    <Center
      id="s1"
      h={{ base: "500px", sm: "110vh", xl: "1180px" }}
      maxH={{ base: "500px", sm: "900px", xl: "980px" }}
      w="full"
      backgroundImage="url('/assets/1.png')"
      backgroundSize={{ base: "110%", lg: "80%", xl: "70%", "2xl": "1000px" }}
      backgroundPosition={"bottom"}
      backgroundRepeat={"no-repeat"}
    >
      {!isMobile && (
        <Center position="relative" top={{ base: "-320px", xl: "-280px" }}>
          <Image
            src="/assets/logo_pedcor.png"
            width="400px"
            height="200px"
            objectFit={"contain"}
            display={{ base: "none", lg: "block" }}
          />
        </Center>
      )}
    </Center>
  );
}
